<template>
    <div>
        <CCard class="p-2"> 
            <CCardHeader>
                <CRow><CButton size="sm" class="btn btn-link back-btn"
				@click="backToTable()"><i class="fa fa-arrow-left"></i> Back</CButton>
		    	</CRow>
            </CCardHeader>
            <CCardBody>
                    <CRow >        
						<CCol lg="1">

								<img style="max-height: 100px; max-width:100px;" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg">
						</CCol> 
						<CCol lg="4">
						<span >
							<h4 class="mb-0 text-primary">
                                {{dataParams.vendor_name}}
							</h4>
							<p>
							<small>
                                {{dataParams.vendor_type_name ? dataParams.vendor_type_name.setting_name :"-"}}
							 </small><br/>
							
							<strong style="text-transform:uppercase; font-size:12px;color:green">{{dataParams.status ? dataParams.status : "-"}}</strong>
							</p>
						</span>
						</CCol>
						
      	        	</CRow>
                 <CCard class="p-4">
                    <CRow class="mb-2"> 
                        <CCol lg="12">
                            <strong style="font-size:14px">Vendor Information</strong>
                        </CCol>	
					</CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Vendor Name
                        </CCol>
                        <CCol lg="8">
                            <strong> {{dataParams.vendor_name}} </strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Contact Number
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.contact_no}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Email Address
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.email}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Address 1
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.address_a}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Address 2
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.address_b ? dataParams.address_b : "-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Owner's Name
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.owners_name ? dataParams.owners_name :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Owner's Contact No.
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.owners_contact_no ? dataParams.owners_contact_no :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Coordinator's Name
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.coordinator_name ? dataParams.coordinator_name :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Coordinator's Contact No.
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.coordinator_contact_no ? dataParams.coordinator_contact_no :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Vendor Type
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.vendor_type_name ? dataParams.vendor_type_name.setting_name :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Tax Type
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.tax_type ? dataParams.tax_type :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Date Started
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.date_started ? dataParams.date_started :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Date Separated
                        </CCol>
                        <CCol lg="8">
                            <strong>{{dataParams.date_seperated ? dataParams.date_seperated :"-"}}</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4">
                            Status
                        </CCol>
                        <CCol lg="8">
                            <strong class="text-capitalize">{{dataParams.status}}</strong>
                        </CCol>
                    </CRow> 
                </CCard>
	        </CCardBody>

        </CCard>
    </div>
  
	        	
		
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
export default {
	mounted(){  
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				name: "",
				contact_no: "",
				email: "",
				birthday: "",
				home_address: "",
				gender: "",
				personnel_type_name: "",
				employment_type: "direct_hire",
				vendor_id: "",
				employee_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				date_started: "",
				date_seperation: "",
				philhealth_no: "",
				sss_no: "",
				status: "active",
				remarks: "",
				emergency_name: "",
				emergency_contact_no: "",
				bank_account: "",
				daily_rate: "",
				tin_no: "",
				pag_ibig_no: "",
				reason_of_separation: "",

			},
			editMode: false,
			selectedRow: {},
			showCollapse: false,

			setting_type : ''
		}
	},
	name: 'Tables',
	components: { },
	methods: { 
		 backToTable(){
	    	this.$router.push('/data/vendor')
	    },
    watch: {
		'dataParams.name' : function(val) {
			this.dataParams.name = val.toUpperCase()
		}
	},


    getData(){

            this.$showLoading(true)
            axios.get(config.api_path+"/vendor/"+this.$route.params.id)
            .then(response=>{
                this.$showLoading(false)
                this.dataParams = response.data.data
            })
            .catch(err => {
            this.$showLoading(false)
            }) 
            },


        submit(){
	    	var ax = {};
	    	if(this.dataParams.vendor_id == ""){
	    		this.dataParams.vendor_id = null;
	    	}

			if(this.dataParams.employment_type == 'direct_hire') {
				this.dataParams.vendor_id = null;
			}

	    	if(this.dataParams.employee_id == ""){
	    		this.dataParams.employee_id = null;
	    	}  

	    	if(this.dataParams.birthday == "" || this.dataParams.birthday == null){
	    		this.dataParams.birthday = null;
	    	}
	    	else{
	    		this.dataParams.birthday = moment(this.dataParams.birthday).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_seperation == "" || this.dataParams.date_seperation == null){
	    		this.dataParams.date_seperation = null;
	    	}
	    	else{
	    		this.dataParams.date_seperation = moment(this.dataParams.date_seperation).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_started == "" || this.dataParams.date_started == null){
	    		this.dataParams.date_started = null;
	    	}
	    	else{
	    		this.dataParams.date_started = moment(this.dataParams.date_started).format('YYYY-MM-DD');
	    	}

			this.dataParams.daily_rate = (this.dataParams.daily_rate) ? this.dataParams.daily_rate : 0;

			this.dataParams.is_company_with_agency = config.global_user_data.company && config.global_user_data.company.company_type.toLowerCase() === 'trucking with agency' ? 1 : 0;
	    	
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/personnel/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/personnel", this.dataParams)
	    	}
	    	ax.then(response => {
				const { status } = response.data;
				
				if(status === 'duplicate') {
					Swal.fire({
						title: 'Personnel name already exists.',
						text,
						icon: 'error', 
					})
						return;
				}

	    		var text = 'Personnel successfully added!';
	    		if(this.editMode){
	    			text = 'Personnel successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					name: "",
					contact_no: "",
					email: "",
					birthday: "",
					home_address: "",
					gender: "",
					personnel_type: "",
					employment_type: "direct_hire",
					vendor_id: "",
					employee_id: "",
					depot_id: "",
					date_started: "",
					date_seperation: "",
					philhealth_no: "",
					sss_no: "",
					status: "active",
					remarks: "",
					emergency_name: "",
					emergency_contact_no: "",
					bank_account: "",
					daily_rate: "",
				}
	    		this.getData();
	    		this.getDataInactive();
				this.getDataAll();
	    	})
	    },


        getVendor(){

            axios.get(config.api_path+'/vendor?page=1&limit=10000000')
            .then(response => {
                this.vendorList = response.data;
                this.vendorList.data = this.vendorList.data.map((value, index)=>{
                    value.value = value.id;
                    value.label = value.vendor_name;
                    return value;
                })

                
            })

            },
        getPersonnelType(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'personnel_type'
                }
                    })
                .then(response => {
                this.personnelTypeList = response.data; 
                })

            },


         getDepot(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'depot'
                }
                    })
                .then(response => {
                this.depotList = response.data; 
                })

                },
        updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
			console.log(data)
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },
        updateGender(data){
	    	this.dataParams.gender = data;
	    },

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    }

 	}
}
</script>
